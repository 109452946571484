import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Container, Heading, Link,
} from '@chakra-ui/react';
import Layout from '../components/layout';
import data from '../data.json';

const Regulamento = ({ location }) => (
  <Layout location={location.pathname}>
    <Container padding={4}>
      <Box my={5}>
        <Heading as="h1" size="xl" textAlign="center" padding={4}>
          {`Regulamento ${data.home.edicao} OCI`}
        </Heading>
        <Heading as="h3" fontWeight="normal" size="md" textAlign="center">
          Não consegue visualizar o PDF? Acesse
          {' '}
          <Link fontWeight="bold" isExternal href="/documentos/Regulamento2024.pdf?v=1">este link</Link>
        </Heading>
      </Box>
    </Container>
    <Box as="object" type="application/pdf" data="/documentos/Regulamento2024.pdf?v=1" maxW="100vw" mx="auto" w="210mm" h="297mm">
      Aqui ficaria o conteúdo do pdf. Tente acessar o link acima.
    </Box>
  </Layout>
);

Regulamento.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default Regulamento;
